import useToken from "./components/login/useToken";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";

import LoginPage from "./pages/Login";
import "./App.css";
import Layout from "./components/layout/Layout";
import TodosLosPendientesPage from "./pages/TodosLosPendientes";
import TodosLosEstudiantesPage from "./pages/TodosLosEstudiantes";
import AccesoTemporalPage from "./pages/AccesoTemporal";
import NuevoEstudiante from "./components/estudiantes/EstudianteNuevo";
import TodosLosIngresosPage from "./pages/TodosLosIngresos";
import TodasLasHabitacionesPage from "./pages/TodasLasHabitaciones";
import TodasLasLlavesPage from "./pages/TodasLasLlaves";
import TodosLosEgresosPage from "./pages/TodosLosEgresos";
import TodosLosResumenesPage from "./pages/TodosLosResumenes";
import OKModal from "./components/dialogos/dialogoOK";
import Backdrop from "./components/layout/backdrop";
import AjustesPage from "./pages/Ajustes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InicioPage from "./pages/Inicio";

function App() {
  //inicio de toda la aplicacion
  const { token, setToken } = useToken(); //guarda lo datos recibidos del usuario logeado:
  //Id (UsrId), nombre, usuario, cas_y_niv (nombre, IDCasa, nivel), usuariovalido, sesion, casasel, nombrecasasel, nivel
  const [casaSel, setCasaSel] = useState(token.casaSel); //variable para tener el IDCasa seleccionado a la mano
  //  const [esInvitado, setEsInvitado] = useState(false);
  const [mostrarNuevo, setMostrarNuevo] = useState(true); //indica si se debe mostrar la pantalla de nuevo estudiante
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  }); //dialogo de avisos y confirmaciones
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false); //indica si se debe mostrar el dialogo o no
  const [nivel, setNivel] = useState(token.nivel); //var para tener el nivel del usuario a la mano
  const vers = "1.25";

  function estableceNivel(IDCasa) {
    //saca el nivel de la casa del token
    //console.log(token);
    const casNiv = JSON.parse(token.cas_y_niv); //convierte el texto de cas_y_niv a un objeto
    Object.keys(casNiv).forEach((key) => {
      //para cada casa, saca el IDCasa
      if (casNiv[key].IDCasa === IDCasa) {
        //si el IDCasa es el de la llave
        setNivel(casNiv[key].Nivel); //establece el nivel
      }
    });
  }

  function palomeaOK() {
    //funcion que recibe el click en el boton del dialogo
    setMuestraDialogoOK(false); //oculta el dialogo
    if (!dialogoOK.mostrandoError) {
      //si no se indica que hubo error
      if (dialogoOK.recargar) {
        //si se indica que se debe recargar
        setDialogoOK({ recargar: false }); //se quita la bandera de recargar
      }
    }
  }

  function terminaInvitado() {
    //funcion que corre al dar click en "guardar" el invitado
    setMostrarNuevo(false); //se oculta la ventana de"nuevo estudiante"
    const nvoToken = {
      casaSel: null, //se quita la casa del token
    };
    setToken(nvoToken); //se guarda el nvo token, ya sin la casa
  }

  function EsConserje() {
    //funcion que regresa "true" si el usuario es conserje, "false" en caso contrario
    return nivel === 3;
  }

  function esInvitado() {
    //funcion que regresa "true" si el usuario es invitado, "false" en caso contrario
    return nivel === 4;
  }

  function getNombreCasaSel(IDCasa) {
    console.log(IDCasa);
    //funcion que regresa el nombre de la casa
    const casNiv = JSON.parse(token.cas_y_niv); //convierte el texto del cas_y_niv en objeto
    Object.keys(casNiv).forEach((key) => {
      //recorremos todos los objetos
      if (casNiv[key].IDCasa === IDCasa) {
        //si el IDCasa del objeto es el mismo que se solicito
        console.log(casNiv[key].Casa);
        return casNiv[key].Casa; //regesa el nombre de la casa
      }
    });
  }

  if (token?.ErrorLogin === "") {
    //aqui entra despues del login
    //si no se ha seleccionado casa
    if (
      casaSel === "" ||
      casaSel === undefined ||
      nivel === "" ||
      nivel === undefined
    ) {
      //revisamos si trae una sola casa
      const casyniv = JSON.parse(token.cas_y_niv); //sacamos el texto de cas_y_niv como objeto
      if (casyniv.length === 1) {
        //en caso de tener solo 1 casa asignada, las pone como seleccionadas
        setCasaSel(casyniv[0].IDCasa);
        estableceNivel(casyniv[0].IDCasa);
      }
    }
    //si es nivel 4, invitado
    if (esInvitado()) {
      return (
        //muestra el layout con el nombre de la casa, bandera "es invitado", unicamente muestra la pantalla de estudiante nuevo
        <Layout
          nombreCasa={getNombreCasaSel(casaSel)}
          esInvitado={esInvitado()}
          vers={vers}
        >
          {mostrarNuevo && ( //por default motrar nuevo viene true. solo al darle "guardar" se hace false
            <NuevoEstudiante //pantalla nuevo estudiante
              esInvitado={esInvitado()}
              extras={token.extras}
              terminaInvitado={terminaInvitado}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
            />
          )}
          {
            mostrarNuevo && (
              <Backdrop />
            ) /*fondo negro para no poder mover nada*/
          }
          {muestraDialogoOK && ( //dialogo para avisos
            <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
          )}
          {muestraDialogoOK && <Backdrop zindex={49} /> /*fondo para dialogo*/}
          <ToastContainer />
        </Layout>
      );
    }
    return (
      //si no es invitado y ya se seleccionó casa
      <Layout //muestra el layout completo
        setCasaSeleccionada={setCasaSel}
        setNivel={estableceNivel}
        nombreCasa={token.nombreCasaSel}
        casaSel={token.casaSel}
        nivel={nivel}
        vers={vers}
      >
        <ToastContainer />
        {/*las diferentes pantallas: */}
        <Routes>
          {nivel === 1 || nivel === 2 ? (
            <Route
              path="/estudiantes"
              element={
                <TodosLosEstudiantesPage casaSel={casaSel} nivel={nivel} />
              }
            />
          ) : null}
          {nivel === 1 || nivel === 2 ? (
            <Route
              path="/ingresos"
              element={<TodosLosIngresosPage casaSel={casaSel} nivel={nivel} />}
            />
          ) : null}
          {nivel === 1 || nivel === 2 ? (
            <Route
              path="/egresos"
              element={<TodosLosEgresosPage casaSel={casaSel} nivel={nivel} />}
            />
          ) : null}
          {nivel === 1 || nivel === 2 ? (
            <Route
              path="/habitaciones"
              element={
                <TodasLasHabitacionesPage casaSel={casaSel} nivel={nivel} />
              }
            />
          ) : null}
          {nivel === 1 || nivel === 2 ? (
            <Route
              path="/llaves"
              element={<TodasLasLlavesPage casaSel={casaSel} nivel={nivel} />}
            />
          ) : null}
          {nivel === 1 || nivel === 2 ? (
            <Route
              path="/resumenes"
              element={
                <TodosLosResumenesPage casaSel={casaSel} nivel={nivel} />
              }
            />
          ) : null}
          <Route
            path="/pendientes"
            element={<TodosLosPendientesPage casaSel={casaSel} nivel={nivel} />}
          />
          {nivel === 1 ? (
            <Route
              path="/temporal"
              element={<AccesoTemporalPage casaSel={casaSel} nivel={nivel} />}
            />
          ) : null}
          {nivel === 1 || nivel === 2 ? (
            <Route
              path="/ajustes"
              element={<AjustesPage casaSel={casaSel} nivel={nivel} />}
            />
          ) : null}
          {["/*", "/seleccionaCasa", "/"].map((path, index) => (
            <Route
              path={path}
              element={
                casaSel > 0 && !EsConserje() ? (
                  <InicioPage casaSel={casaSel} nivel={nivel} />
                ) : casaSel === 0 ? (
                  <h1>Selecciona la casa</h1>
                ) : EsConserje ? (
                  <TodosLosPendientesPage casaSel={casaSel} nivel={nivel} />
                ) : null
              }
              key={index}
            />
          ))}
        </Routes>
      </Layout>
    );
  }
  //aqui
  return (
    <div className="contPrinc">
      <LoginPage setToken={setToken} vers={vers} />
    </div>
  );
}

export default App;
